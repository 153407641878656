<!-- =========================================================================================
  File Name: VideoEdit.vue
  Description: Video Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/video/pixinvent
========================================================================================== -->

<template>
  <div id="page-video-edit">

    <vs-alert :active.sync="video_not_found" color="danger" title="Video Not Found">
      <span>Video record with id: {{ $route.params.videoId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link to="/a/videos" class="text-inherit underline">All Videos</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="video_data">

      <div class="tabs-container px-6 pt-6" slot="no-body">

        <vs-tabs class="tab-action-btn-fill-conatiner" v-model="activeTab">
          <vs-tab icon="icon-video" icon-pack="feather" label="General">
            <div class="tab-text">
              <video-edit-tab-general :data="video_data" class="mt-4"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-info" icon-pack="feather" label="Upload Video">
            <div class="tab-text">
              <video-edit-tab-upload :data="video_data" class="mt-4"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import VideoEditTabGeneral from './VideoEditTabGeneral';
import VideoEditTabUpload from './VideoEditTabUpload';

// Store Module

export default {
  components: {
    VideoEditTabGeneral,
    VideoEditTabUpload,
  },
  data() {
    return {
      video_data: null,
      video_not_found: false,
      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      this.fetchVideoData(this.$route.params.videoId);
    },
  },
  methods: {
    fetchVideoData(videoId) {

      this.$http.get(`videos/${videoId}`)
        .then(response => {

          if (response.data.data) {
            this.video_data = response.data.data;

            const pictureId = this.video_data.thumbnail_id || 'default';
            this.video_data.photoURL = `https://app-cdn.rapidseminars.com/thumbnails/${pictureId}.jpeg`;
          }

        })
        .catch(error => {
          this.$vs.loading.close();

          if (error.response.status === 404) {
            this.video_not_found = true;
            return;
          }
          console.error(error);
        });
    },
  },
  created() {
    this.fetchVideoData(this.$route.params.videoId);
  },
};

</script>
