<!-- =========================================================================================
  File Name: VideoEditTabInformation.vue
  Description: Video Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/video/pixinvent
========================================================================================== -->

<template>
  <div id="video-edit-tab-info">

    <!-- Content Row -->
    <div class="vx-row mb-5">
      <div class="vx-col md:w-1/3 sm:w-full pt-3">
        <vs-input v-model="data_local.name" v-validate="'required|alpha_num'" class="w-full" label="Video Name" name="name"
                  tabindex="1"/>
        <span v-show="errors.has('first_name')" class="text-danger text-sm">{{ errors.first('name') }}</span>

        <vs-input v-model="data_local.author_id" v-validate="'required|alpha_num'" class="w-full" label="Author ID" name="author_id" tabindex="2"/>
        <span v-show="errors.has('author_id')" class="text-danger text-sm">{{ errors.first('author_id') }}</span>


        <label class="vs-input--label" for="public">Public?</label>
        <vs-switch v-model="data_local.public" label="Public?" name="public" tabindex="3"/>
        <span v-show="errors.has('public')" class="text-danger text-sm">{{ errors.first('public') }}</span>

      </div>

      <div class="vx-col md:w-2/3 sm:w-full pt-3">
        <div class="flex items-start flex-col sm:flex-row">
          <img :alt="`${data_local.name} Video Thumbnail`" :src="data_local.photoURL" class="mr-8 rounded"
               style="width:25rem;"/>
          <div>
            <input id="file" ref="file" class="d-none pb-2 mt-3" type="file" @change="selectFile"><br/>
            <vs-button class="mr-4 sm:mb-0 mb-2" @click="submitPicture">Upload photo</vs-button>
            <vs-button color="danger" type="border" @click="removePicture">Remove</vs-button>
            <p class="text-sm mt-2">Allowed JPG or PNG. Max size of 2MB</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button :disabled="!validateForm" class="ml-auto mt-2" @click="initiateVideoUpdate">Save Changes
          </vs-button>
          <vs-button class="ml-4 mt-2" color="warning" type="border" @click="resetData">Reset</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    selectFile() {
      const files = this.$refs.file.files;

      if (files.length > 0) {
        this.file = files[0];
      }

    },
    initiateVideoUpdate() {

      const payload = {
        name: this.data_local.name,
        author_id: this.data_local.author_id,
        public: this.data_local.public,
      };

      this.$vs.loading();

      this.$http.post(`videos/${this.data_local.id}`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.data && response.data.data) {

            this.data_local = response.data.data;
            this.data_local.photoURL = `https://app-cdn.rapidseminars.com/thumbnails/${response.data.data.thumbnail_id}.jpeg`;

            return this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s settings have been updated`,
              color: 'success',
            });

          }

        })
        .catch(exception => {
          this.$vs.loading.close();



          let response;
          if (exception.response) {
            response = exception.response;
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            color: 'danger',
          });
        });
    },
    removePicture() {

      this.$vs.loading();

      this.$http.delete(`videos/${this.data.id}/thumbnail`)
        .then((response) => {
          this.$vs.loading.close();

          if (response.response) {
            response = response.response;
          }

          if (response.data.data) {

            if (response.data.data.profile_picture) {
              this.data_local.photoURL = `https://app-cdn.rapidseminars.com/thumbnails/${response.data.data.profile_picture}.jpeg`;
            }

            this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.first_name}'s profile picture has been removed.`,
              color: 'success',
            });
          }

        })
        .catch(e => {
          this.$vs.loading.close();
        });
    },
    submitPicture() {

      if (typeof this.file !== 'object') {
        return this.$vs.notify({
          title: 'Error',
          text: 'You must select a file to upload',
          color: 'danger',
        });
      }
      this.$vs.loading();

      const formData = new FormData();
      formData.append('upload', this.file);

      this.$http.post(`videos/${this.data.id}/thumbnail`, formData)
        .then((response) => {
          this.$vs.loading.close();

          if (response.response) {
            response = response.response;
          }

          if (response.data.data) {

            if (response.data.data.thumbnail_id) {
              this.data_local.photoURL = `https://app-cdn.rapidseminars.com/thumbnails/${response.data.data.thumbnail_id}.jpeg`;
            }

            this.$vs.notify({
              title: 'Success',
              text: `${this.data_local.name}'s' settings have been updated`,
              color: 'success',
            });
          }

        })
        .catch(e => {
          this.$vs.loading.close();
        });

    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>
