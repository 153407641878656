<!-- =========================================================================================
  File Name: VideoEditTabInformation.vue
  Description: Video Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/video/pixinvent
========================================================================================== -->

<template>
  <div id="video-edit-tab-info">
    <div class="vx-row">
      <div class="vx-col w-full md:w-fyll">

        <!-- Col Header -->
        <div class="flex items-end">
          <feather-icon class="mr-2" icon="VideoIcon" svgClasses="w-5 h-5"/>
          <span class="leading-none font-medium">Upload Video to OSIDEX Stream</span>
        </div>

        <div class="vx-row">
          <!-- Col Content -->
          <div class="vx-col w-1/2 mt-6">

            <span class="leading-none font-medium">Select a File to Upload</span>

            <vs-upload ref="uploads" :show-upload-button="false" :single-upload="true" accept="mp4"
                       @change="initiateVideoSelect"/>
          </div>

          <div class="vx-col w-1/2 mt-6">
            <div class="mt-4">
              <span class="leading-none font-medium">Upload Progress &mdash; {{ previousStatus | title }}</span>
              <vs-progress :percent="percent" color="primary"></vs-progress>
              <vs-textarea :value="progress" height="215px"></vs-textarea>
            </div>

            <div class="mt-4">

            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button :disabled="disabled" class="ml-auto mt-2" @click="initiateVideoUpdateOsidex">Save Changes
          </vs-button>
          <vs-button :disabled="disabled" class="ml-4 mt-2" color="warning" type="border" @click="resetData">Reset
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';
import {Upload} from 'tus-js-client';

export default {
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disabled: false,
      data_local: JSON.parse(JSON.stringify(this.data)),
      selected_video: undefined,
      percent: 0,
      progress: '',
      previousStatus: 'created'
    };
  },
  computed: {},
  methods: {
    initiateVideoSelect(path, files) {

      files = files.filter(file => !file.remove);

      if (files.length < 1) {

        return this.$vs.notify({
          title: 'Failed to Select Video',
          text: 'Please select a video to upload.',
          color: 'danger',
        });

      }

      const file = files[0];

      if (file.type !== 'video/mp4') {
        return this.$vs.notify({
          title: 'Failed to Select Video',
          text: 'Please select a file that is an MP4',
          color: 'danger',
        });
      }

      this.selected_video = file;
      this.progress += `Video File Selected...\n`;

    },
    async initiateVideoUpdate() {

      if (this.selected_video === undefined) {
        return this.$vs.notify({
          title: 'Failed to Upload Video',
          text: 'Please select a video to be uploaded',
          color: 'danger',
        });
      }

      let file = this.selected_video;

      // Stupid JavaScript scoping -_-
      let lcl = this;

      let response = await this.$http.get(`application/cloudflare`);
      if (!response.data.data.bearer) {
        return this.$vs.notify({
          color: 'danger',
          title: 'Failed to authorize',
          text: 'An error occurred while fetching Cloudflare Auth Keys',
        });
      }

      var options = {
        endpoint: 'https://api.cloudflare.com/client/v4/accounts/138f96be39d82c0f339fa9cf8c996cc0/stream',
        headers: {
          'Authorization': `Bearer ${response.data.data.bearer}`,
        },
        chunkSize: 5 * 1024 * 1024,
        resume: true,
        metadata: {
          filename: `${lcl.data_local.name}.mp4`,
          filetype: 'video/mp4',
          name: lcl.data_local.name,
          requireSignedURLS: !lcl.data_local.public || true,
        },
        uploadSize: file.size,
        onError: function(error) {
          lcl.disabled = false;
          throw error;
        },
        onProgress: function(bytesUploaded, bytesTotal) {
          const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
          lcl.percent = parseFloat(percentage);
        },
        onSuccess: async function() {

          const index = upload.url.lastIndexOf('/') + 1;
          const mediaId = upload.url.substr(index).split('?')[0];
          lcl.progress += `Video Upload Completed...\n    Media ID: ${mediaId}\n`;
          lcl.progress += `Beginning video publication...\n`;

          lcl.$vs.notify({
            title: 'Video Uploading.',
            text: 'The video has been uploaded. Publishing...',
            color: 'success',
          });

          const duration = await lcl.validateFile(file);

          lcl.$http.post(`videos/${lcl.data_local.id}/publish`, {
            platform_id: mediaId,
            duration: duration,
          })
            .then(response => {
              lcl.disabled = false;

              if (response.status === 200) {
                lcl.progress += `Video publication complete!\n`;
                return lcl.$vs.notify({
                  title: 'Video Published.',
                  text: 'The video is now published.',
                  color: 'success',
                });
              }

            })
            .catch(exception => {

              lcl.disabled = false;

              if (exception.response) {



                return;

              }



            });

        },
      };

      lcl.disabled = true;

      var upload = new Upload(file, options);
      upload.start();

      lcl.progress += `Video Upload Started...\n`;

    },
    async getVideoById(videoId) {

      try {
        const response = await this.$http.get(`https://api.osidex.stream/videos/${videoId}`);
        return response.data.data;
      } catch (e) {
        return undefined;
      }

    },

    async initiateVideoUpdateOsidex() {

      if (this.selected_video === undefined) {
        return this.$vs.notify({
          title: 'Failed to Upload Video',
          text: 'Please select a video to be uploaded',
          color: 'danger',
        });
      }


      // Stupid JavaScript scoping -_-
      let lcl = this;

      await this.$http.post(`videos/${lcl.data_local.id}/recreate`)
        .then(response => {
          lcl.disabled = false;

          if (response.status === 200) {
            lcl.data_local = response.data.data;
          }

        })
        .catch(error => {
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to re-create video',
            text: error.response ? error.response.data.data : 'An unknown error has occurred.',
          });
        })


      let file = this.selected_video;


      let response = await this.$http.post(`https://api.osidex.stream/videos/${this.data_local.osidex_id}/upload`);
      if (!response.data.data) {
        return this.$vs.notify({
          color: 'danger',
          title: 'Failed to authorize',
          text: 'An error occurred while fetching the upload token for this upload',
        });
      }

      const uploadUrl = response.data.data.url;

      const xhr = new XMLHttpRequest();
      xhr.open('PUT', uploadUrl);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.setRequestHeader('x-amz-acl', 'public-read');

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentage = ((event.loaded / event.total) * 100).toFixed(2);
          lcl.percent = parseFloat(percentage);
        }
      };

      xhr.upload.onload = async (event) => {

        try {
          const response = await lcl.$http.patch(`https://api.osidex.stream/videos/${lcl.data_local.osidex_id}`,
            {status: 'uploaded'}
          );
        } catch (e) {

          console.error(e);

          return lcl.$vs.notify({
            title: 'Failed to mark video as uploaded',
            text: 'An error occurred while marking the video as uploaded.',
            color: 'error',
          });
        }

        const mediaId = this.data.osidex_id;
        lcl.progress += `Video Upload Completed...\n    Media ID: ${mediaId}\n`;
        lcl.progress += `Beginning video publication...\n`;

        lcl.$vs.notify({
          title: 'Successfully uploaded video',
          text: 'The master has now been marked for processing. You may now navigate away.',
          color: 'success',
        });

      };

      lcl.disabled = true;
      lcl.progress += `Video Upload Started...\n`;


      const interval = setInterval(async () => {

        try {

          let videoId = this.data.osidex_id;

          const video = await this.getVideoById(videoId);

          if (!video) return;

          let previousStatus = this.previousStatus;
          this.previousStatus = video.status;

          if (previousStatus === 'uploaded' && video.status === 'processing') {
            return lcl.$vs.notify({
              title: 'Video Processing',
              text: 'The video is now processed.',
              color: 'success',
            });
          }

          if (previousStatus === 'processing' && video.status === 'processed') {
            return lcl.$vs.notify({
              title: 'Video Processed',
              text: 'Your video is now being deployed to our Global CDN.',
              color: 'success',
            });
          }

          if (video.status === 'ready') {
            clearInterval(interval);

            const duration = await lcl.validateFile(file);

            lcl.$http.post(`videos/${lcl.data_local.id}/publish`, {
              platform_id: videoId,
              duration: duration,
            })
              .then(response => {
                lcl.disabled = false;

                if (response.status === 200) {
                  lcl.progress += `Video publication complete!\n`;

                  return lcl.$vs.notify({
                    title: 'Video Published',
                    text: 'The video is now published.',
                    color: 'success',
                  });
                }

              })
              .catch(exception => {

                lcl.disabled = false;

                if (exception.response) {



                  return;

                }



              });

          }


        } catch (e) {
          console.error(e);
        }

      }, 2500);

      xhr.send(file);
    },
    resetData() {
      this.data_local = Object.assign({}, this.data);
    },
    validateFile(file) {
      return new Promise((resolve, reject) => {

        const video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function() {

          window.URL.revokeObjectURL(video.src);

          if (video.duration > 0) resolve(video.duration);
          else resolve(0);

        };

        video.src = URL.createObjectURL(file);

      });

    },
  },
};
</script>

<style>

.con-img-upload .img-upload {
  background: rgb(157, 54, 75);
}

</style>
